import toolUrl from './modules/tool-url';

async function bootUp() {
  // @ts-ignore
  // eslint-disable-next-line import/no-dynamic-require
  await new Promise(resolve => require(['./init'], resolve));

  if (!process.env.IS_MODERN) {
    // @ts-ignore
    // eslint-disable-next-line import/no-dynamic-require
    await new Promise(resolve => require(['./init-legacy'], resolve));
  }

  const edgeVersion = Number.parseInt(
    /edge\/(\d+)/i.exec(navigator.userAgent)?.[1]
  );

  if (!Number.isNaN(edgeVersion) && edgeVersion <= 18) {
    // @ts-ignore
    // eslint-disable-next-line import/no-dynamic-require
    await new Promise(resolve => require(['./init-legacy-edge'], resolve));
  }

  toolUrl.readUrl();

  // @ts-ignore
  // eslint-disable-next-line import/no-dynamic-require
  require(['dojo/ready', 'eCounty/Main'], (ready, Main) => {
    // @ts-ignore
    const serverData = window.serverData;

    ready(() => {
      Main.init({ serverData });
    });
  });
}

bootUp();
