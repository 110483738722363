/**
 * Saves the toolName from the URL query `tool`
 */
export function readUrl() {
  const url = new URL(location.href);

  let toolName = url.searchParams.get('tool');

  if (!toolName) {
    return;
  }

  // Remove excess slash that's added automatically
  if (toolName.endsWith('/')) {
    toolName = toolName.replace('/', '');
  }

  history.replaceState({ toolName }, '');
}

/**
 * Get the tool saved from the URL
 * @returns {FactoryTypes.ModuleNames}
 */
export function getTool() {
  return history.state?.toolName || null;
}

export function clear() {
  const url = new URL(location.href);
  url.searchParams.delete('tool');

  history.replaceState(null, '', url.toString());
}
